export const projectsList = [
  {
    name: "PROJETOS",
    marketplace: true,
  },
  {
    name: "GRUPO",
    number: '1',
    gender: "question",
    question: "PARCERIAS \n ESTRATÉGICAS",
    link: "https://heiway.sharepoint.com/:v:/r/sites/Mastertech/Shared%20Documents/General/Lardado[…]%20Pedados%20-%20Projetos/GRUPO_1.ogg?csf=1&web=1&e=mXwmmb"
  },
  {
    name: "GRUPO",
    number: '2',
    gender: "question",
    question: "EXPERIÊNCIA \n DO CONSUMIDOR",
    link: "https://heiway.sharepoint.com/:p:/r/sites/Mastertech/Shared%20Documents/General/Lardado[…]x?d=w21995d4a33c742e58d611e3e8556538e&csf=1&web=1&e=saL3qv"
  },
  {
    name: "GRUPO",
    number: '3',
    gender: "question",
    question: "EXPERIÊNCIA \n DO CONSUMIDOR",
    link: "https://heiway.sharepoint.com/:p:/r/sites/Mastertech/Shared%20Documents/General/Lardado[…]x?d=w71558fe7ee6846e99284ffe37d9d8e2b&csf=1&web=1&e=NOVVP1"
  },
  {
    name: "GRUPO",
    number: '4',
    gender: "question",
    question: "EXPERIÊNCIA \n DO CONSUMIDOR",
    link: "https://heiway.sharepoint.com/:p:/r/sites/Mastertech/Shared%20Documents/General/Lardado[…]x?d=wbc873b10412d49369775cd74da2f14c5&csf=1&web=1&e=xcswzq"
  },
  {
    name: "GRUPO",
    number: '5',
    gender: "question",
    question: "EMBALAGENS",
    link: "https://heiway.sharepoint.com/:p:/r/sites/Mastertech/Shared%20Documents/General/Lardado[…]x?d=wf6495b336cde497b82d71c51f33da324&csf=1&web=1&e=FrJXXV"
  },
  {
    name: "GRUPO",
    number: '6',
    gender: "question",
    question: "PARCERIAS \n ESTRATÉGICAS",
    link: "https://heiway.sharepoint.com/:v:/r/sites/Mastertech/Shared%20Documents/General/Lardado[…]%20Pedados%20-%20Projetos/GRUPO_6.mp4?csf=1&web=1&e=zqDa6A"
  },
  {
    name: "GRUPO",
    number: '7',
    gender: "question",
    question: "PARCERIAS \n ESTRATÉGICAS",
    link: "https://heiway.sharepoint.com/:p:/r/sites/Mastertech/Shared%20Documents/General/Lardado[…]x?d=w91d9f2c6035e4bdfbb220ee45d49db2f&csf=1&web=1&e=O7RmTm"
  },
]
