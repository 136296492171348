import React from 'react';
import CardProject from '../../../components/ui/CardProject/CardProject.js';
import { projectsList } from '../../constants/projectsList.js';
import "./Projects.scss";

const Projects = () => {

  const handleRedirect = (info) => {
    window.open(info.link, '_blank');
  }

  return (
    <main className='Projects'>
      <section>
        {projectsList?.map((info, index) =>
          <CardProject onClick={() => {
            handleRedirect(info)
          }} key={index} info={info} />
        )}
      </section>
    </main>
  )
}

export default Projects
